import React from 'react';
import { navigate } from 'gatsby';

const Main = () => {
  React.useEffect(() => {
    navigate('/dashboard/merchants');
  });
  return null;
};
export default Main;
